import { appendUrlParams, UrlPayload } from './utils/url'
import { isSmavaTenant } from './utils/commons'
import { StorageType } from './utils/storage'

import './initializer';
import './utils/safariBackButtonWorkaround'

enum ImodEntryPoint {
  LandingPage = 'landing_page',
  BankList = 'banklist',
  Taurine = 'taurine',
  AffiliateAPI = 'api',
  LeadForm = 'lead_form',
}

enum TaurineFormConfig {
  DDF = 'ddf',
  DAC = 'ddf_marketing',
  Classic = 'classic',
  Offers = 'offers',
  Bank = 'bank',
  LeadForm = 'lead_form',
  DDF_Smava = 'ddf_smava',
  Smava = 'smava',
  DAC_Smava = 'ddf_smava_marketing'
}

enum TaurineEntryPoint {
  First = 'first',
  BaseReceived = 'baseReceived',
  Furthest = 'furthest'
}

enum Version {
  V1 = 'v1',
  V2 = 'v2'
}

export interface EmbedConfig {
  advertisementId: string
  formConfig?: string
  storageType?: StorageType
  elementId?: string
  data?: UrlPayload
  amplitudeId?: string
  entryPoint?: string
  backUrl?: string
  deactivateHubble?: boolean
  dataHjAllowIframe?: boolean
  imodEntryPoint?: ImodEntryPoint
  paapiId?: string
  minHeight?: number
  version?: Version
  minAmount?: number
  maxAmount?: number
  hasDac?: boolean
}

function getElement(elementId: string | undefined) {
  if (!elementId) {
    return document.body
  }

  return document.getElementById(elementId) || document.body
}

interface IFrameOptions {
  minHeight?: number
}

function iFrameConfigure(
  iframe: string,
  opts: IFrameOptions = {}
) {
  const iFrameEl = document.getElementsByName(iframe)?.[0] as HTMLIFrameElement
  
  iFrameEl?.setAttribute('scrolling', 'no')
  iFrameEl?.style.setProperty('overflow', 'hidden')
  iFrameEl?.style.setProperty('min-height', `${opts.minHeight || 700}px`)

  return iFrameEl
}

function getImodEntryPoint(imodEntryPoint: string, taurineEntryPoint: string) {
  if (
    imodEntryPoint === ImodEntryPoint.LandingPage && 
    taurineEntryPoint === TaurineEntryPoint.BaseReceived
  ) {
    return ImodEntryPoint.Taurine
  }

  return imodEntryPoint
}

function embedIframe({
  advertisementId,
  storageType = 'localStorage',
  elementId,
  amplitudeId,
  entryPoint,
  backUrl = '',
  data = {},
  deactivateHubble = false,
  dataHjAllowIframe = false,
  imodEntryPoint = ImodEntryPoint.LandingPage,
  paapiId,
  minHeight,
  version = Version.V1,
  minAmount,
  maxAmount,
  hasDac = false,
}: EmbedConfig) {
  const isSmavaTenantForUUID = isSmavaTenant('__TENANT_UUID__')

  if (isSmavaTenantForUUID) {
    data.formConfig = !data.formConfig || data.formConfig === TaurineFormConfig.Smava 
      ? TaurineFormConfig.DDF_Smava 
      : (hasDac ? TaurineFormConfig.DAC_Smava : data.formConfig)
  } else {
    switch(data.formConfig) {
      case TaurineFormConfig.Offers:
        // Force formConfig to `ddf` and show banklist as landing page.
        data.formConfig = TaurineFormConfig.DDF
        imodEntryPoint = ImodEntryPoint.BankList
        break
      case TaurineFormConfig.LeadForm:
        if (imodEntryPoint !== ImodEntryPoint.Taurine) {
          imodEntryPoint = ImodEntryPoint.LeadForm
        }
        break
      case TaurineFormConfig.Classic:
      default:
        // Force formConfig to `ddf` as it has stronger conversion.
        data.formConfig = hasDac ? TaurineFormConfig.DAC : TaurineFormConfig.DDF
    }

    const OLD_OFINA_PRODUCT_IDS = [218, 247]
    // Map old ofina product to new ones
    if (data?.productId && OLD_OFINA_PRODUCT_IDS.includes(data.productId)) {
      data.productId = parseInt('__OFINA_PRODUCT_ID__')
    }
  }

  const newImodEntryPoint = getImodEntryPoint(imodEntryPoint, entryPoint as string)

  switch(newImodEntryPoint) {
    case ImodEntryPoint.LandingPage:
      window.frameman.load(getElement(elementId), {
        src: appendUrlParams(
          '__IMOD_LP_EMBED_URL__',
          {
            ...data,
            embedded: true
          },
          { 
            advertisementId,
            entryPoint: entryPoint ?? TaurineEntryPoint.First,
            version,
            minAmount,
            maxAmount,
            ...(hasDac && { hasDac }),
          },
        ),
        iframeName: 'imod_landing_page_widget',
        storage: storageType,
      })

      iFrameConfigure('imod_landing_page_widget', { minHeight })

      return
    case ImodEntryPoint.BankList:
      window.frameman.load(getElement(elementId), {
        src: appendUrlParams(
          '__IMOD_LP_BANKLIST_URL__',
          {
            ...data,
            embedded: true
          },
          { 
            advertisementId,
            entryPoint: entryPoint ?? TaurineEntryPoint.BaseReceived,
            version,
            minAmount,
            maxAmount,
            ...(hasDac && { hasDac }),
          },
        ),
        iframeName: 'imod_banklist_widget',
        storage: storageType,
      })

      iFrameConfigure('imod_banklist_widget', { minHeight })

      return
    case ImodEntryPoint.AffiliateAPI:
      window.frameman.load(getElement(elementId), {
        src: appendUrlParams(
          '__IMOD_LP_EMBED_REG_ROUTE_URL__',
          {
            ...data,
            embedded: true
          },
          {
            advertisementId,
            entryPoint: entryPoint ?? TaurineEntryPoint.First,
            paapiId,
            version,
            minAmount,
            maxAmount,
            ...(hasDac && { hasDac }),
          },
        ),
        iframeName: 'imod_affiliate_api_widget',
        storage: storageType,
      })

      iFrameConfigure('imod_affiliate_api_widget', { minHeight })

      return
    case ImodEntryPoint.LeadForm:
      window.frameman.load(getElement(elementId), {
        src: appendUrlParams(
          '__IMOD_LP_EMBED_REG_ROUTE_URL__',
          data,
          {
            advertisementId,
            amplitudeId: amplitudeId ?? '__AMPLITUDE_ID__',
            entryPoint: entryPoint ?? TaurineEntryPoint.Furthest,
            version,
            minAmount,
            maxAmount
          },
        ),
        iframeName: 'loan_application_widget',
        storage: storageType,
      })

      iFrameConfigure('loan_application_widget', { minHeight })

      return
  }

  window.frameman.load(getElement(elementId), {
    src: appendUrlParams(
      '__TAURINE_URL__',
      data,
      {
        advertisementId,
        amplitudeId: amplitudeId ?? '__AMPLITUDE_ID__',
        entryPoint: entryPoint ?? TaurineEntryPoint.Furthest,
        version,
        minAmount,
        maxAmount
      },
    ),
    backUrl,
    iframeName: 'loan_application_widget',
    storage: storageType,
    deactivateHubble,
    dataHjAllowIframe
  })

  iFrameConfigure('loan_application_widget', { minHeight })
}

export default embedIframe
