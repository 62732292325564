/**
 * 2021-03
 *
 * Safari browser back button workaround
 *
 * ISSUE DESCRIPTION
 *
 * What should happen:
 * On navigating back using the browser back button Safari should usually resume a working page state from Back-Forward Cache.
 *
 * What actually happens:
 * Trying to apply the Back-Forward Cache on a page rendering a cross-domain iframe leaves the page in a 'frozen' state.
 * We can detect this by checking if the 'pagehide' event's property persisted is true.
 *
 * After that a 'pageshow' gets fired. Ususally this event should also have it's property persisted true
 * if resuming from back-forward cache, but it never is.
 * So, in a nutshell, Safari fires a correct 'pagehide' event, but does not fire a correct 'pageshow' event.
 *
 * Due to this Safari behavior we have no reliable possibility to detect a resume from Back-Forward Cache in the 'pageshow' event.
 *
 *
 * SOLUTION DESCRIPTION (Safari only):
 * Fortunately we can detect it by using the 'pagehide' event's correct persisted property.
 *
 * When changing to 'frozen' state ('pagehide' event with persisted == true), we write a PAGE_IS_PERSISTED object to session storage.
 * On 'pageshow' we try to get the PAGE_IS_PERSISTED object from session storage.
 *
 * If the object is present we remove the object from session storage and then reload the entire page.
 * Important: it is critically necessary to remove the PAGE_IS_PERSISTED object from session storage,
 * otherwise the page would end in a loop reloading after every pageshow)
 *
 * This is definitely not the best solution, but since Safari does not fire a correct 'pageshow' event with persisted == true,
 * we unfortunately have no other option.
 *
 *
 * RESSOURCES:
 * - https://stackoverflow.com/questions/17432899/javascript-bfcache-pageshow-event-event-persisted-always-set-to-false
 * - https://stackoverflow.com/questions/11979156/mobile-safari-back-button/12652160
 * - https://developers.google.com/web/updates/2018/07/page-lifecycle-api
 * - https://stackoverflow.com/questions/24046/the-safari-back-button-problem
 * - https://wicg.github.io/page-lifecycle/
 * - https://bugs.webkit.org/show_bug.cgi?id=156356
 */

const { getStorage } = require('./storage')

const PAGE_IS_PERSISTED = 'PAGE_IS_PERSISTED'

const storage = getStorage('sessionStorage')

const isSafari = () =>
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === '[object SafariRemoteNotification]'
  })(
    !window['safari'] ||
      (typeof safari !== 'undefined' && window['safari'].pushNotification),
  )

;(() => {
  if (isSafari()) {
    window.addEventListener(
      'pagehide',
      (event) => {
        if (event.persisted) {
          // If the event's persisted property is `true` the page is about
          // to apply the Back-Forward Cache, which is in the frozen state.
          storage.setItem(PAGE_IS_PERSISTED, 'true')
        }
      },
      { capture: true },
    )

    window.addEventListener(
      'pageshow',
      (event) => {
        if (storage.getItem(PAGE_IS_PERSISTED)) {
          storage.removeItem(PAGE_IS_PERSISTED)

          window.location.reload()
        }
      },
      { capture: true },
    )
  }
})()
