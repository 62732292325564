/**
 * Giving privision to handle if script load with asynchronosly on client.
 * Existing way of integrations should not be altered so create this inititalizer
 * 
 * This code runs only if host uses `window.embedLoader` to integrate the embed iframe
 */
;(() => {
    if (
        typeof window == 'undefined' ||
        !(window.embedLoader && typeof window.embedLoader.waitFor === 'string')
    ) {
        return;
    }

    // If `isAllowedToCheckForVarExistance` false then code stop check for variable availability in window.
    let isAllowedToCheckForVarExistance = true;

    const handleMaxLookupTimer = (max_time_lookup: number) => {
        let maxLookupTimer: any = null;

        const clearTimer = () => {
            if (maxLookupTimer) {
                clearTimeout(maxLookupTimer);
            }
        }

        maxLookupTimer = setTimeout(() => {
            isAllowedToCheckForVarExistance = false;
        }, max_time_lookup);

        return {
            clearTimer,
        }
    }

    const waitForElementsToLoad = (globalvars: [string, string]) => {
        try {
            if (!globalvars.length) {
                return;
            }

            const loadedVars = {};
            const globalvarsList = globalvars.slice(0);
            const {
                lookup_time = 200,
                max_time_lookup = 10000
            } = window.embedLoader || {};
            const { clearTimer } = handleMaxLookupTimer(max_time_lookup);

            const handleIfVariablesLoaded = () => {
                if (
                    Object.keys(loadedVars).length == globalvars.length &&
                    window.embedLoader &&
                    window.embedLoader.onReady
                ) {
                    clearTimer();
                    window.embedLoader.onReady();
                }
            }

            const waitForVariableToAppear = (varToLoad: string) => {
                if (varToLoad) {
                    if (window[varToLoad]) {
                        loadedVars[varToLoad] = true;
                        handleIfVariablesLoaded();
                    } else if (isAllowedToCheckForVarExistance) {
                        setTimeout(() => {
                            waitForVariableToAppear(varToLoad);
                        }, lookup_time);
                    }
                }
            }

            while (globalvarsList.length) {
                const varName = globalvarsList.shift();
                waitForVariableToAppear(varName || '');
            }
        } catch (e) {
        }
    }

    /* 
     * Due to build job, it is hard to find in which bundle we are in. 
     * we may have to use hacky way to fix this. 
     * To avoid that, we are providing "waitFor" property can be configured by user,
     * we wait until this get's loaded. this value can be teal_embed_iframe/ffg_embed_iframe.
    */
    waitForElementsToLoad([window.embedLoader.waitFor, "frameman"]);
})();

export default {};